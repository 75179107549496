import React from 'react';
import {Dropdown} from "react-bootstrap";
import styles from "./Header.module.scss";
import Image from "next/image";
import {s3Link} from "../../config/ethereum-config";
import profileImg from "../../public/avatar-creator.jpg";
import PolygonIcon from "./svg/PolygonSVG";
import AvalancheImg from "./svg/Avalanche_SVG";
import Link from "next/link";
import ProfileIcon from "./svg/ProfileIcon";
import MyItems from "./svg/MyItems";
import DisconnectIcon from "./svg/DisconnectIcon";
import {CloneIcon} from "../icons";
import {useTranslation} from "next-i18next";
import { useRouter } from 'next/router';

function LoggedInUser({isUserLoggedIn, userDetails, walletAddress, cAddress,
                          account_balance, copyAddress, chainType, currLocale,
                      userUrl, signout, signIn}) {

    const { t } = useTranslation();
    const router = useRouter()  
    
    const pageNavigation = async() => {
        if(router?.pathname == "/nft/profiles/[profileName]"){
            await router.push(`/nft/profiles/${userUrl}`);
            router.reload();
        }
    }
    const userCommonLinks = (
        <>
            <Link locale={currLocale} href={"/nft/profiles/" + userUrl}>
                <a className={`d-flex align-items-center ${styles.list}`} onClick={pageNavigation}>
                    <ProfileIcon />
                    {t('common:my_profile')}
                </a>
            </Link>
            <Link locale={currLocale} href={"/nft/profiles/" + userUrl}>
                <a className={`d-flex align-items-center ${styles.list}`}>
                    <MyItems />
                    {t('common:my_items')}
                </a>
            </Link>
            <Link locale={currLocale} href={"/nft/my-collections/" + userUrl}>
                <a className={`d-flex align-items-center ${styles.list}`}>
                    <MyItems />
                    {t('common:my_collections')}
                </a>
            </Link>
            <Link href="#">
                <a className={`d-flex align-items-center ${styles.list}`} onClick={() => signout()}>
                    <DisconnectIcon />
                    {t('common:disconnect')}
                </a>
            </Link>
        </>
    );
    
    if(isUserLoggedIn){
        return (
            <Dropdown className={styles.header__upload}>
                <Dropdown.Toggle className='p-0' id="dropdown-basic">
                    {(userDetails && userDetails.profilePhoto)
                        ?
                        <>
                            <Image
                                src={s3Link + "profileimage/" + userDetails.profilePhoto}
                                alt="profile"
                                width={40}
                                height={40} /></>
                        :
                        <>
                            <Image
                                src={profileImg}
                                alt="profile"
                                width={40}
                                height={40} />
                        </>
                    }
                </Dropdown.Toggle>
                <ul className={`dropdown-menu d-none d-lg-block ${styles.dropdownMenu} ${styles.createMenuList}`}>
                    <div className={`mb-0 ${styles.userName}`} title={userDetails.displayName}>
                        {(userDetails && userDetails.displayName && userDetails.displayName.length > 15) ? userDetails.displayName.substring(0, 14) + "..." : (userDetails && userDetails.displayName)}
                    </div>
                    <div className={styles.user__number}>
                            <span className="me-2" title={walletAddress}>
                                {cAddress && walletAddress ? "Copied !" : (walletAddress).substring(1, 8) + "..." + (walletAddress).slice(-4)}
                            </span>
                        <CloneIcon onClick={() => copyAddress(walletAddress)} />
                    </div>
                    <div className={styles.balanceMenu}>
                        <div className='d-flex'>
                            <div className='me-2'>
                                {chainType === "polygon" ? <PolygonIcon /> : <AvalancheImg />}

                            </div>
                            <div className={styles.balance}>
                                <span className={styles.subTitle}>{t('common:balance')}</span>
                                <div className={styles.ethPrice}>
                                    {account_balance}
                                    {chainType === "avax" ? " AVAX" : " AVAX"}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.createList}>
                        {userCommonLinks}
                    </div>
                </ul>
                <Dropdown.Menu className={`d-lg-none ${styles.createMenuList}`}>
                    <div className={`mb-0 ${styles.userName}`}>
                        {(userDetails && userDetails.displayName && userDetails.displayName.length > 15) ? userDetails.displayName.substring(0, 14) + "..." : (userDetails && userDetails.displayName)}
                    </div>
                    <div className={styles.user__number}>
                        <span className="me-2">
                            {walletAddress && walletAddress.substring(1, 15) + "..."}
                        </span>
                        <CloneIcon onClick={() => copyAddress(walletAddress)} />
                    </div>
                    <div className={styles.balanceMenu}>
                        <div className='d-flex'>
                            <div className='me-2'>
                                <AvalancheImg />
                            </div>
                            <div className={styles.balance}>
                                <span className={styles.subTitle}>Balance</span>
                                <div className={styles.ethPrice}>{account_balance} AVAX</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.createList}>
                        {userCommonLinks}
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        )
    }
    return (
        <button className={`btn buttonStroke buttonSmall d-none d-md-block ${styles.header__upload}`}
                onClick={signIn}>{t('common:Connect_wallet')}</button>
    )
}

export default LoggedInUser;