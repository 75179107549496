
import React, { useState } from 'react';
import Image from "next/image";
import profileImg from "../../public/avatar-creator.jpg";

export default function SearchImg(props) {
    
  const [error, setError] = useState(false);

  const  onError = () => {
      setError(true);
    }

  return (
    <Image src={ error ? profileImg :  (props.src && props.src)  } alt="UpYo NFT" onError={onError}  width={32} height={32} layout="fixed" loading='lazy' />
  )
}
