import React, { Component } from 'react';
import Image from "next/image";
import Link from "next/link";
import { Col, Container } from "react-bootstrap";
import Router from 'next/router';
import { ToastContainer, toast } from 'react-toastify';
import { withTranslation } from 'next-i18next';
import { withRouter } from 'next/router';

// Loader
import OnlyLoader from "../common/loader/onlyLoader";
// Images
import logo_light from "../../public/21Spades-logo-m.png";
import logo_dark from "../../public/21Spades-logo-m.png";
import logo_mobile from "../../public/21Spades-logo-m.png";
import logo_mobile_dark from "../../public/21Spades-logo-m.png";
import google_play from "../../public/upyo__app.svg";
import { capitalize } from '../../helper/utilityHelper'
import icons8_twitterx_48 from '../../public/icons/icons8_twitterx_48.png'
//icons
import {
    FooterTwitterIcon,
    // icons8_twitter,
    // icons8_twitterx_48,
    FooterInstagramIcon,
    FooterTelegramIcon,
    FooterYoutubeIcon,
    FooterFacebookIcon,
    ReadMoreIcon
} from '../icons'

// API helper
import { apiHelperBlockchain } from '../../helper/apiHelper';
// styles
import 'react-toastify/dist/ReactToastify.css';
import styles from "./Footer.module.scss";
class Footer extends Component {
    _unmounted = false;

    state = {
        selectedOption: { value: 'English', label: 'English' },
        smallWidth: false,
        email: "",
        emailError: "",
        emailLoader: false,
        categoryList: [],
        ifId : false
    };

    componentDidMount() {
        this._unmounted = true;
        let router = Router.router

        if (this._unmounted) {
            window.addEventListener("resize", this.handleResize);
            if (this.props && this.props.locale && this.props.locale === 'ar') {
                this.setState({
                    selectedOption: { value: 'Arabic', label: 'Arabic' }
                })
            }
        }
        this.getAllCategory();

        if(window.location.href.includes("/id")){
            this.setState({ifId : true})
        }
    }

    getAllCategory = async () => {
        await apiHelperBlockchain('category-list', 'GET').then(resp => {
            console.log("resp.data.data",resp.data)
            if (resp.data.status) {
                this.setState({ categoryList: resp.data.data });
            }
        }).catch(error => console.error(`Error: ${error}`));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.locale !== this.props.locale) {
            if (this._unmounted) {
                this.setState({
                    selectedOption: this.props.locale === 'en' ? { value: 'English', label: 'English' } : { value: 'Arabic', label: 'Arabic' }
                })
            }
        }
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
        this._unmounted = false;
    }
    handleResize = () => {
        if (this._unmounted) {
            if (window.innerWidth < 992) {
                this.setState({ smallWidth: true });
            }
            else {
                this.setState({ smallWidth: false });
            }
        }
    };

    // select lang
    handleChange = (selectedOption) => {
        if (this._unmounted) {
            const router = Router.router;
            this.setState({ selectedOption }, () => {
                Router.router.push(router.asPath, undefined, { locale: (this.state.selectedOption.value === 'Arabic' ? 'ar' : 'en') });
            });
        }
    };

    handleChangeEmail = (e) => {
        let em = e.target.value;
        let err = "";
        if (em === "") {
            err = this.props.t('common:error_email');
        }
        else if (!this.validateEmail(em)) {
            err = this.props.t('common:error_valid_email');
        }
        this.setState({ email: em, emailError: err });
    }

    validateEmail = (email) => {
        return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
    }

    saveSubscription = (e) => {
        e.preventDefault();
        if (this.state.email === "") {
            this.setState({ emailError: this.props.t('common:error_email') });
        } else if (!this.validateEmail(this.state.email)) {
            this.setState({ emailError: this.props.t('common:error_valid_email') });
        } else {
            this.setState({ emailLoader: true });
            let router = Router.router
            let referUrl = process.env.hostBaseUrl + '/' + this.props.currLocale + router.asPath;

            let pData = {
                email: this.state.email,
                pageId: this.props.pageId ? this.props.pageId : '',
                pageName: this.props.pageName ? this.props.pageName : '',
                referUrl: referUrl ? referUrl : '',
                action: 'arrow'
            };

            // save-subscribe
            apiHelperBlockchain('main-site-subscribe', 'POST', pData).then(res => {
                if (res.data.status) {
                    toast(res.data.message);
                }
                else {
                    this.setState({ emailError: res.data.message });
                }
                this.setState({ emailLoader: false, email: "" });
            }).catch(error => console.error(`Error: ${error}`));
        }
    }

    render() {
        const currYear = new Date().getFullYear();
        const { t, currLocale, router } = this.props;

        const linkHref = this.state.ifId ? "/id" : "/did"

        const didRoutes = ["/[did]", "/[did]/certs","/[did]/web", "/[did]/wallet", "/[did]/about-us", "/[did]/use-cases", "/[did]/how-it-works", "/[did]/identity-access-management", "/[did]/healthcare", "/[did]/metaverse", "/[did]/education", "/[did]/use-case" ]
        const didDashboardRoutes = ["/[did]/dashboard", "/[did]/credentials", "/[did]/issue", "/[did]/template", "/[did]/verifiable", "/[did]/verification", "/[did]/create-template", "/[did]/verification-template", "/[did]/verification-history", "/[did]/verify-credentials", "/[did]/login", "/[did]/signup",]

        return (
            <>
                {!(router?.pathname && (didRoutes.includes(router.pathname) || didDashboardRoutes.includes(router.pathname))) ?
                    <>
                        <footer className={styles.footer}>
                            <Container>
                                <div className={`d-md-flex ${styles.footer__top} ${styles.border_bottom}`}>
                                    <Col md={6}>
                                        <div className={styles.footer__details}>{t('common:join_com')}</div>
                                        <ul className={`d-flex ${styles.footer__connection_list}`}>
                                             <li><Link href="https://twitter.com/@21SpadesNFT"><a title="twitter" target='_blank' rel="noopener"><FooterTwitterIcon /></a>
                                            </Link></li>
                                            <li><Link href="https://t.me/+XyKl3RHYu-QxNWMx"><a title="Telegram" target='_blank' rel="noopener"><FooterTelegramIcon /></a></Link></li>
                                            <li><Link href="https://www.instagram.com/21spades.io"><a title="instagram" target='_blank' rel="noopener"><FooterInstagramIcon /></a></Link></li>
                                        </ul>
                                    </Col>
                                </div>
                                <div className={styles.footer__center}>
                                    <div className="row">
                                        <Col md={6} lg={6} className={styles.footerWidget}>
                                            <Link locale={currLocale} href="/nft">
                                                <a className={`d-flex logo_style ${styles.footer__logo}`}>
                                                    <span className={`light ${styles.dflex}`}>
                                                        <span className="d-none d-sm-block">
                                                            <Image src={logo_light} alt="21 Spades" width={102} height={50} layout="fixed" />
                                                        </span>
                                                        <span className="d-block d-sm-none">
                                                            <Image src={logo_mobile} alt="21 Spades" width={40} height={40} layout="fixed" />
                                                        </span>
                                                    </span>
                                                    <span className={`dark ${styles.dflex}`}>
                                                        <span className="d-none d-sm-block">
                                                            <Image src={logo_dark} alt="21 Spades" width={102} height={50} layout="fixed" />
                                                        </span>
                                                        <span className="d-block d-sm-none">
                                                            <Image src={logo_mobile_dark} alt="21 Spades" width={40} height={40} layout="fixed" />
                                                        </span>
                                                    </span>
                                                </a>
                                            </Link>
                                            <div className={styles.footer__info}>
                                                {t('common:footer_desc')}
                                            </div>
                                            <div className={styles.footer__theme}>
                                                <div className={`text-start ${styles.footer__details}`}>{t('common:21spades_update_email_lbl')}</div>
                                                <form className={styles.subscription} onSubmit={this.saveSubscription}>
                                                    <input
                                                        className={`form-control ${styles.subscription__input}`}
                                                        type="text"
                                                        value={this.state.email}
                                                        onChange={this.handleChangeEmail}
                                                        placeholder={t('common:enter_email')}
                                                    />
                                                    <button type="submit" aria-label='subscribe' className={`p-0 ${styles.subscription__btn}`}>
                                                        {this.state.emailLoader ? <OnlyLoader />
                                                            :
                                                            <ReadMoreIcon className={styles.colors} />
                                                        }
                                                    </button>
                                                </form>

                                                {this.state.emailError && <span className='valmsg'>{this.state.emailError}</span>}
                                            </div>
                                        </Col>
                                        <Col md={6} lg={2} className={styles.footerWidget}>
                                            <div className={styles.footer__head}>
                                                {t('common:marketplace')}
                                            </div>
                                            <div className={styles.footer__menu}>
                                                <ul>
                                                    <li><Link locale={currLocale} href={"/nft/category"}><a className={styles.footer__link}>{t('common:all_items')}</a></Link></li>
                                                    {this.state.categoryList && this.state.categoryList.length > 0 ?
                                                        this.state.categoryList.map((item) => {
                                                            return (
                                                                <li key={item.page_name}>
                                                                    <Link locale={currLocale} href={`/nft/category/${encodeURI(item.page_name)}`}>
                                                                        <a className={styles.footer__link}>
                                                                            {currLocale && currLocale === "en" ? capitalize(item.category_name) : item.category_name_arabic}
                                                                        </a>
                                                                    </Link>
                                                                </li>
                                                            )
                                                        }) :
                                                        null}
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col md={6} lg={2} className={styles.footerWidget}>
                                            <div className={styles.footer__head}>
                                                {t('common:resources')}
                                            </div>
                                            <div className={styles.footer__menu}>
                                                <ul>
                                                   
                                                    <li>
                                                        <Link locale={currLocale} href="/"><a className={styles.footer__link}><span>{t('common:newsletter')}</span></a></Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col md={6} lg={2} className={styles.footerWidget}>
                                            <div className={styles.footer__head}>
                                                {t('common:company')}
                                            </div>
                                            <div className={styles.footer__menu}>
                                                <ul>
                                                    <li>
                                                        <Link locale={currLocale} href="/nft/about"><a className={styles.footer__link}><span>{t('common:about')}</span></a></Link>
                                                    </li>
                                                   
                                                    <li>
                                                        <Link locale={currLocale} href="/nft/contact"><a className={styles.footer__link}><span>{t('common:help_center')}</span></a></Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </Col>
                                    </div>
                                </div>
                                <div className={styles.footer__foot}>
                                    <div className={styles.footer__copyright}>
                                        {t('common:copyright', { year: currYear })}
                                    </div>
                                    {/* /nft/terms
                                    /nft/privacy */}
                                    <div className={styles.footer__note_link}>
                                        <Link locale={currLocale} href="/nft/terms"><a className={styles.footer__link}>{t('common:terms')}</a></Link>
                                        <Link locale={currLocale} href="/nft/privacy"><a className={styles.footer__link}>{t('common:privacy_policy')}</a></Link>
                                    </div>
                                </div>
                            </Container>
                        </footer>
                        <ToastContainer />
                    </>
                    : (!(router?.pathname && (didDashboardRoutes.includes(router.pathname)))) &&
                    <>
                        <footer className={`${styles.footer} ${styles.didFooter}`}>
                            <Container>
                            <div className={`d-md-flex ${styles.footer__top} ${styles.border_bottom}`}>
                                    <Col md={6}>
                                        <div className={styles.footer__details}>{t('common:join_com')}</div>
                                        <ul className={`d-flex ${styles.footer__connection_list}`}>
                                            <li><Link href="https://twitter.com/upyocom"><a title="twitter" target='_blank' rel="noopener"><icons8_twitterx_48 /></a></Link></li>
                                            <li><Link href="https://www.instagram.com/upyonft"><a title="instagram" target='_blank' rel="noopener"><FooterInstagramIcon /></a></Link></li>
                                            <li><Link href="https://t.me/UPYOnft"><a title="Telegram" target='_blank' rel="noopener"><FooterTelegramIcon /></a></Link></li>
                                            <li><Link href="https://www.youtube.com/channel/UCCg3H6ZX1YRKgorLkGMuz-w"><a title="youtube" target='_blank' rel="noopener"><FooterYoutubeIcon /></a></Link></li>
                                            <li><Link href="https://web.facebook.com/UPYOcom"><a title="facebook" target='_blank' rel="noopener"><FooterFacebookIcon /></a></Link></li>
                                        </ul>
                                    </Col>
                                </div>
                                <div className={styles.footer__center}>
                                    <div className="row">
                                        <Col md={6} lg={2} className={styles.footerWidget}>
                                            <Link locale={currLocale} href={linkHref}>
                                                <a className={`d-flex logo_style ${styles.footer__logo}`}>
                                                    <span className={`light ${styles.dflex}`}>
                                                        <span>
                                                            <Image src={logo_light} alt="21 Spades" width={102} height={33} layout="fixed" />
                                                        </span>
                                                    </span>
                                                    <span className={`dark ${styles.dflex}`}>
                                                        <span>
                                                            <Image src={logo_dark} alt="21 Spades" width={102} height={33} layout="fixed" />
                                                        </span>
                                                    </span>
                                                </a>
                                            </Link>
                                            <div className={styles.footer__info}>
                                            21Spades ID helps you protect your digital documents from fraud and makes them verifiable in seconds.
                                            </div>
                                            <div className={styles.footer__theme}>
                                                <div className={`text-start ${styles.footer__details}`}>{t('common:21spades_update_email_lbl')}</div>
                                                <form className={styles.subscription} onSubmit={this.saveSubscription}>
                                                    <input
                                                        className={`form-control ${styles.subscription__input}`}
                                                        type="text"
                                                        value={this.state.email}
                                                        onChange={this.handleChangeEmail}
                                                        placeholder={t('common:enter_email')}
                                                    />
                                                    <button type="submit" className={`p-0 ${styles.subscription__btn}`}>
                                                        {this.state.emailLoader ? <OnlyLoader />
                                                            :
                                                            <ReadMoreIcon className={styles.colors} />
                                                        }
                                                    </button>
                                                </form>

                                                {this.state.emailError && <span className='valmsg'>{this.state.emailError}</span>}
                                            </div>
                                        </Col>
                                        
                                        <Col md={6} lg={2} className={styles.footerWidget}>
                                            <div className={styles.footer__head}>
                                                Solutions
                                            </div>
                                            <div className={styles.footer__menu}>
                                                <ul>
                                                    <li><Link href={this.state.ifId?"/id/certs" :"/did/certs"}><a className={styles.footer__link}>Certs</a></Link></li>
                                                    <li><Link href={this.state.ifId?"/id/web" : "/did/web"}><a className={styles.footer__link}>Web3 ID</a></Link></li>
                                                    <li><Link href={this.state.ifId? "/id/wallet" : "/did/wallet"}><a className={styles.footer__link}>Wallet</a></Link></li>
                                                </ul>
                                            </div>
                                        </Col>
                                        
                                        <Col md={6} lg={2} className={styles.footerWidget}>
                                            <div className={styles.footer__head}>
                                                Industries
                                            </div>
                                            <div className={styles.footer__menu}>
                                                <ul>
                                                    <li><Link href={`${linkHref}/education`}><a className={styles.footer__link}>Education</a></Link></li>
                                                    <li><Link href={`${linkHref}/metaverse`}><a className={styles.footer__link}>Metaverse</a></Link></li>
                                                    <li><Link href={`${linkHref}/healthcare`}><a className={styles.footer__link}>Healthcare</a></Link></li>
                                                    <li><Link href={`${linkHref}/identity-access-management`}><a className={styles.footer__link}>Identity & Access Management</a></Link></li>
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col md={6} lg={2} className={styles.footerWidget}>
                                            <div className={styles.footer__head}>
                                            Quick Links
                                            </div>
                                            <div className={styles.footer__menu}>
                                                <ul>
                                                    <li><Link href={this.state.ifId?"/id/#AboutUs" : "/did/#AboutUs"}><a className={styles.footer__link}>About us</a></Link></li>
                                                    <li><Link locale={currLocale} href={"https://21Spades.com/en/nft/contact"}><a className={styles.footer__link}>Contact Us</a></Link></li>
                                                </ul>
                                            </div>
                                        </Col>
                                    </div>
                                </div>
                                <div className={styles.footer__foot}>
                                    <div className={styles.footer__copyright}>
                                        {t('common:copyright', { year: currYear })}
                                    </div>
                                    <div className={styles.footer__note_link}>
                                        <Link locale={currLocale} href="https://21Spades.com/en/nft/terms"><a className={styles.footer__link}>{t('common:terms')}</a></Link>
                                        <Link locale={currLocale} href="https://21Spades.com/en/nft/privacy"><a className={styles.footer__link}>{t('common:privacy_policy')}</a></Link>
                                    </div>
                                </div>
                            </Container>
                        </footer>
                    </>
                }
            </>

        )
    }
}
export default withTranslation()(withRouter(Footer));